<template>
	<el-card>
		<advanced-search-bar>
			<template>
				<form-item label="选择日期:" style="width:300px;">
					<el-date-picker v-model="queryForm.date" type="daterange" start-placeholder="开始日期"
						end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width:340px;" size="mini"
						:clearable="false" :picker-options="pickerOptions">
					</el-date-picker>
				</form-item>
				<domains-input :domainNames.sync="queryForm.domainNames"></domains-input>
				<form-item label="粒度:" style="width:180px;">
					<el-select v-model="queryForm.groupByDays" size="mini" class="w-100">
						<el-option label="按天" :value="true"></el-option>
						<el-option label="聚合" :value="false"></el-option>
					</el-select>
				</form-item>
				<terms-input :terms.sync="queryForm.terms"></terms-input>
				<form-item>
					<el-button type="primary" size="mini" icon="el-icon-search" @click="searchEvent">查 询</el-button>
					<el-button type="primary" size="mini" @click="downloadTask">下 载</el-button>
					<!-- <el-button type="primary" @click="showFilter" size="mini">过 滤</el-button> -->
					<el-button type="primary" @click="showEdit" size="mini">显示编辑</el-button>
				</form-item>
			</template>
			<template #advanced v-if="false">
			</template>
		</advanced-search-bar>
		<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);">
		</div>
		<terms-table ref="dataTable" class="mt-3 w-100" :tableData="tableData" :tableColumns="filterTableColumns" :tableProps="tableProps"></terms-table>
		<table-columns-drawer :visible.sync="drawer.edit" :data="sortedTableColumns" :checkedKeys.sync="checkedKeys" :sortedKeys.sync="sortedKeys" :namespace="namespace + '_terms'" :updateColumns="updateColumns"></table-columns-drawer>
		<!-- <el-drawer title="过滤" :visible.sync="drawer.filter" direction="rtl">
			<div style="padding: 0 20px;">
				<div><small>DomainName:</small></div>
				<div>
					<el-input v-model="filter.domainName" size="mini" placeholder="模糊匹配" style="width:179px;"></el-input>
				</div>
			</div>
		</el-drawer> -->
	</el-card>
</template>

<script>
	import TermsTable from '@/components/jscx/terms-table.vue'
	import Moment from 'moment'
	import config from './minixs/terms.js'
	import TableColumnsDrawer from '@/components/jscx/table-columns-drawer.vue'
	import DomainsInput from '@/components/jscx/domains-input.vue'
	import TermsInput from '@/components/jscx/terms-input.vue'
	export default {
		components: {
			TermsTable,
			TableColumnsDrawer,
			DomainsInput,
			TermsInput
		},
		mixins: [config],
		data() {
			return {
				namespace: 'jscx',
				pickerOptions: {
					disabledDate(time) {
						const today = new Date();
						return time.getTime() > today; // 禁用不在范围内的日期
					}
				},
				drawer: {
					edit: false,
					filter: false
				},
				sortedKeys: [],
				checkedKeys: [],
				tableColumns: [{
						label: "Term",
						prop: "term",
						disabled: true,
						sortable: true
					},
					{
						label: "clicks",
						prop: "clicks",
						sortable: true
					},
					{
						label: "epc",
						prop: "epc",
						sortable: true
					},
					{
						label: "estimated_revenue",
						prop: "estimatedRevenue",
						sortable: true
					},
					{
						label: "Date",
						prop: "costDate",
						sortable: false
					},
				],
				sortedTableColumns: [],
				filterTableColumns: [],
				tableProps: {
					groupByDays: false,
					dateFrom: null,
					dateTo: null
				}
			}
		},
		created() {
			const checkedKeys = localStorage.getItem(`${this.namespace}_terms_${process.env.VUE_APP_VERSION}_checked_keys`)
			if (checkedKeys) {
				try {
					this.checkedKeys = JSON.parse(checkedKeys)
				} catch (e) {
					console.log(e)
				}
			} else {
				this.checkedKeys = this.tableColumns.map(v => v.prop)
			}
			const sortedKeys = localStorage.getItem(`${this.namespace}_terms_${process.env.VUE_APP_VERSION}_sorted_keys`)
			if (sortedKeys) {
				try {
					this.sortedKeys = JSON.parse(sortedKeys)
				} catch (e) {
					console.log(e)
				}
			} else {
				this.sortedKeys = this.tableColumns.map(v => v.prop)
			}
			this.updateColumns()
		},
		computed: {
			// filterData() {
			// 	return this.tableData.reduce((lhs, rhs) => {
			// 		const res0 = (new RegExp(this.filter.domainName, "ig")).test(rhs.domainName || '')
			// 		if (res0) {
			// 			lhs.push(rhs)
			// 		}
			// 		return lhs
			// 	}, [])
			// },
		},
		methods: {
			updateColumns() {
				this.sortedTableColumns = this.tableColumns.reduce((lhs, rhs) => {
					const index = this.sortedKeys.indexOf(rhs.prop)
					if (index !== -1) {
						lhs[index] = rhs
					}
					return lhs
				}, new Array(this.tableColumns.length))
				this.filterTableColumns = this.sortedTableColumns.reduce((lhs, rhs) => {
					if (this.checkedKeys.indexOf(rhs.prop) !== -1) {
						lhs.push(rhs)
					}
					return lhs
				}, [])
			},
			showEdit() {
				this.drawer.edit = true
			},
			// showFilter() {
			// 	this.drawer.filter = true
			// }
		}
	}
</script>

<style>
	.terms-domain-checkbox-grid {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
		gap: 10px; /* 复选框间距 */
	}
</style>