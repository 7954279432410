<template>
	<el-drawer title="显示编辑" :visible.sync="visibleSync" direction="rtl" :append-to-body="true">
		<div style="padding: 0 20px;">
			<el-button type="primary" @click="doApply" size="mini" class="ml-4 mb-2">应 用</el-button>
			<el-tree class="jscx-tree" ref="col-tree" draggable :data="data" show-checkbox node-key="prop"
				:allowDrop="allowDrop" :default-checked-keys="checkedKeysSync" @check-change="checkChange"
				@node-drop="handleDrop">
			</el-tree>
		</div>
	</el-drawer>
</template>

<script>
	export default {
		props: {
			data: {
				type: Array,
				default: () => []
			},
			checkedKeys: {
				type: Array,
				default: (() => [])
			},
			sortedKeys: {
				type: Array,
				default: (() => [])
			},
			visible: {
				type: Boolean,
				default: false
			},
			namespace: {
				type: String,
				default: ''
			},
			updateColumns: {
				type: Function,
				default: null
			}
		},
		data() {
			return {
				originalCheckedKeys: [],
			}
		},
		computed: {
			visibleSync: {
				get() {
					return this.visible
				},
				set(value) {
					this.$emit('update:visible', value)
				}
			},
			checkedKeysSync: {
				get() {
					return this.checkedKeys
				},
				set(value) {
					this.$emit('update:checkedKeys', value)
				}
			},
			sortedKeysSync: {
				get() {
					return this.sortedKeys
				},
				set(value) {
					this.$emit('update:sortedKeys', value)
				}
			}
		},
		methods: {
			allowDrop(draggingNode, dropNode, type) {
				return type !== 'inner'
			},
			checkChange(data, checked, indeterminate) {
				this.checkedKeysSync = this.$refs['col-tree'].getCheckedKeys()
			},
			handleDrop(draggingNode, dropNode, dropType, ev) {
				this.originalCheckedKeys = [...this.checkedKeysSync]
				this.sortedKeysSync = dropNode.parent.childNodes.map(v => v.data.prop)
				this.$nextTick(() => {
					this.$refs['col-tree'].setCheckedKeys(this.originalCheckedKeys)
				})
			},
			doApply() {
				localStorage.setItem(`${this.namespace}_${process.env.VUE_APP_VERSION}_checked_keys`, JSON.stringify(this
					.checkedKeysSync))
				localStorage.setItem(`${this.namespace}_${process.env.VUE_APP_VERSION}_sorted_keys`, JSON.stringify(this
						.sortedKeysSync))
				this.updateColumns && this.updateColumns()
				this.visibleSync = false
			}
		}
	}
</script>

<style>
	.jscx-tree .el-tree-node__content .el-tree-node__label {
		text-transform: capitalize;
	}
</style>