<template>
	<form-item label="Terms:" style="width:300px;">
		<el-input placeholder="请选择" @focus="visible=true" size="mini"></el-input>
		<small @click="visible=true" style="cursor:pointer;display:inline-block;width: 150px;margin-left:10px">已选择{{terms.length}}</small>
		<el-dialog title="Terms" :visible.sync="visible" :destroy-on-close="true" @open="dialogOpen">
			<div>
				<el-input v-model="localTerms" clearable size="mini" type="textarea" :rows="10" class="mb-3" placeholder="输入多个关键词需换行"></el-input>
				<el-button type="primary" @click="doConfirm" size="mini">应用</el-button>
			</div>
		</el-dialog>
	</form-item>
</template>

<script>
	export default {
		props: {
			terms: {
				type: Array,
				default: (() => [])
			},
		},
		data() {
			return {
				visible: false,
				localTerms: ''
			}
		},
		methods: {
			dialogOpen() {
				this.localTerms = this.terms.join('\n')
			},
			doConfirm() {
				this.$emit("update:terms", this.localTerms.split('\n').map(v => v.trim()).filter(v => v !== ''))
				this.visible = false
			}
		}
	}
</script>

<style>
</style>